// Variables

@buttonPrimary: #00cd98;
@link-1: #727272;
@link-2: #0086c0;
@iconBlue: #2783c2;
@title: #2d2d2d;
@titleDark: #212123;
@text: #303030;
@placeholder: lighten(@text, 60%);
@iconColor: #cbcbcb;

@inputPadding: 15px;
@fileInputBg: #ebf2fc;
@fileInputIcon: #aab9cd;

@calcWidth: 810px;

@calcCostLeftM: 30px;
@mailMinW: 350px;

@howBg: #eeeeee;
@mainDescr: #7b7b7b;

@howItemDescr: #5e5e5e;


@advantagesBg: white;
@advantageTitle: #272727;
@advantageDescr: #636363;

@ucanBg: #c4d6e4;
@ucanBg2: #a6c6de;
@ucanLinkTitle: #2772a5;
@ucanLinkDescr: #202020;


@footerPreBg: #343e4b;
@footerPreBg_: lighten(desaturate(@footerPreBg, 57%), 9%);

@footerBg: black;
@footerBg_: lighten(@footerBg, 11%);

@subMenuPadding: 20px;

@opacityFooter: 0.7;
@opacityFooterLink: 0.8;




@xl: 1220px;
@l: 960px;
@m: 768px;
@s: 480px;

@xl_: 1219px;
@l_: 959px;
@m_: 767px;
@s_: 479px;

// Mixins

.arrow_bottom(@color:@light, @size:8px) {
  .pseudo;
  border-top: @size solid @color;
  border-left: @size solid transparent;
  border-right: @size solid transparent;
  bottom: -@size - 1;
}

// Styles

body {
  font-family: "Open Sans", sans-serif;
  transition: 1s;
}

h1, h2, h3, h4 {
  font-family: "Open Sans", sans-serif;
}

a {
  transition: 0.3s;
}

.pseudo {
  position: absolute;
  display: block;
  content: "";
}

.teeth(@teethColor) {
  width: 100%;
  top: -8px;
  border-bottom: 8px solid transparent;
  background-image: linear-gradient(@teethColor, @teethColor), linear-gradient(to bottom right, transparent 50.5%, @teethColor 50.5%), linear-gradient(to bottom left, transparent 50.5%, @teethColor 50.5%);
  background-repeat: repeat, repeat-x, repeat-x;
  background-position: 0 0, 8px 0, 8px 0;
  background-size: auto auto, 16px 16px, 16px 16px, 16px 16px, 16px 16px;
  background-clip: padding-box, border-box, border-box, border-box, border-box;
  background-origin: padding-box, border-box, border-box, border-box, border-box;
  background-color: @teethColor;
  background-color: rgba(0, 0, 0, 0);
}

.placeholder {
  font-size: 16px;
  font-style: italic;
  color: @placeholder;
}

@media (min-width: @l) {
  .uk-container {
    max-width: @l + 70px;

    &_calc {
      max-width: @l + 150px;
    }
  }
}

@media (min-width: @xl) {
  .uk-container {
    max-width: @xl + 50px;

    &_calc {
      max-width: @xl + 130px;
    }
  }
}


.sp-icons {
  display: block;
}

.logo-cont {
  line-height: normal;
}

.logo {
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &__text {
    margin: 5px 0 0 10px;
  }

  &__title {
    font-size: 27px;
    color: @iconBlue;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 22px;
  }

  &__beta {
    text-transform: none;
    font-style: italic;
    font-size: 17px;
    font-weight: 700;
    position: relative;
    top: -14px;
    left: 4px;
  }

  &__tagline {
    font-size: 14px;
    color: #818181;
    font-style: italic;
    text-transform: lowercase;
  }

  @media (max-width: @l_) {
    margin: auto;
  }
}

.head-button.head-item {
  @media (max-width: @m_) {
    flex-direction: column;
    align-items: stretch;
    margin-top: 0;
  }
}

.link {

  &:hover {
    cursor: pointer;
  }

  &_before-button {
    margin-top: 5px;
    margin-bottom: 5px;

    @media (min-width: @m) {
      margin-right: 60px;
    }

    &-mini {
      margin-right: 25px;
    }

  }

  &__icon {
    font-size: 15px;
    margin-right: 2px;

    &_blue {
      color: @iconBlue;
    }

    &_green {
      color: @buttonPrimary;
    }
  }

  &__text {
    transition: 0.3s;

    &_icon {
      font-size: 15px;
      color: @link-1;
    }

    &_dotted {
      border-bottom: 1px dotted;
    }

    &_blue {
      color: @link-2;
    }

    &_semibold {
      font-weight: 600;
    }

    &_small {
      font-size: 14px;
    }
  }

  &:hover,
  &:focus {       
    text-decoration: none;

    .link__text_dotted {
      border-color: transparent;
    }
  }
}

.uk-form .button,
.button {
  color: white;
  font-weight: 600;
  transition: 0.3s;

  &:hover,
  &:focus {
    color: white;
  }

  &_m {
    font-size: 15px;
    padding: 7px 20px;
  }

  &_l {
    font-size: 16px;
    padding: 10px 17px;

    @media (max-width: @s_) {
      padding: 5px 10px;
    }

  }

  &_xl {
    font-size: 19px;
    font-weight: 700;
    padding: 17px 17px 17px 25px;

    @media (max-width: @s_) {
      padding: 10px 10px 10px 18px;
    }
  }

  &_dotted {
    font-size: 14px;
    font-weight: 300;
    color: @link-1;
    background-color: white;
    border: 2px dotted @buttonPrimary;

    &:hover,
    &:focus {
      background-color: @buttonPrimary;
      color: white;
    }
  }

  &_green:active,
  &_green {
    background-color: @buttonPrimary;
    box-shadow: 1px 1px 0 darken(@buttonPrimary, 10%);

    &:hover,
    &:focus {
      background-color: darken(@buttonPrimary, 3%);
    }
  }

  &_blue:active,
  &_blue {
    background-color: @iconBlue;
    box-shadow: 1px 1px 0 darken(@iconBlue, 10%);

    &:hover,
    &:focus {
      background-color: darken(@iconBlue, 3%);
    }
  }

  &_arrow {
    position: relative;

    &::before,
    &::after {
      .pseudo;
      top: 0;
      border-left: 10px solid;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      transition: 0.3s;
    }

    &::before {
      z-index: 1;
    }

    &::after {
      top: 1px;
    }

    &-green {

      &:hover,
      &:focus {
        &::before {
          border-left-color: darken(@buttonPrimary, 3%);
        }
      }

      &::before {
        border-left-color: @buttonPrimary;
      }
      &::after {
        border-left-color: darken(@buttonPrimary, 10%);
      }
    }

    &-blue {

      &:hover,
      &:focus {
        &::before {
          border-left-color: darken(@iconBlue, 3%);
        }
      }

      &::before {
        border-left-color: @iconBlue;
      }
      &::after {
        border-left-color: darken(@iconBlue, 10%);
      }
    }

    &-m {
      margin-right: 15px;

      &::before {
        right: -15px;
        border-top-width: 22px;
        border-bottom-width: 22px;
        border-left-width: 15px;
      }
      &::after {
        right: -16px;
        border-top-width: 21px;
        border-bottom-width: 23px;
        border-left-width: 15px;
      }
    }

    &-xl {
      margin-right: 20px;

      &::before {
        right: -20px;
        border-top-width: 33px;
        border-bottom-width: 32px;
        border-left-width: 20px;
        
        @media (max-width: @s_) {
          right: -13px;
          border-top-width: 26px;
          border-bottom-width: 25px;
          border-left-width: 13px;
        }
      }
      &::after {
        right: -21px;
        border-top-width: 33px;
        border-bottom-width: 31px;
        border-left-width: 20px;
        
        @media (max-width: @s_) {
          right: -14px;
          border-top-width: 26px;
          border-bottom-width: 24px;
          border-left-width: 13px;
        }
      }


    }
  }

  &_calc-cost {
    margin-top: -9px;
    margin-left: @calcCostLeftM;

    @media (max-width: @m_) {
      margin-top: 20px;
      margin-left: 0;
    }
  }

}

.head-item {
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: @m_) {
    justify-content: space-between;
  }
}

.title {
  font-size: 35px;
  font-weight: 600;
  color: @title;
  text-align: center;
  line-height: 40px;

  &_banner {
    color: white;
    font-size: 31px;
    text-align: left;
    margin-top: 35px;
    margin-bottom: 50px;
    max-width: @calcWidth;

    @media (max-width: @m_) {
      font-size: 21px;
      line-height: normal;
    }
  }

  &_calc {
    font-size: 26px;
    font-weight: 700;
    color: @titleDark;
    text-align: left;
    margin-left: 115px;
    margin-top: -6px;
    margin-right: 15px;

    @media (max-width: @m_) {
      margin-left: 15px;
      margin-top: 50px;
    }
  }

  &_line-through {
    position: relative;

    &::before {
      .pseudo;
      left: 0;
      right: 0;
      top: 50%;
      z-index: 0;
      border-top: 3px solid #e4e4e4;
    }
    }  &_advantage {
      font-size: 18px;
      color: @advantageTitle;
      line-height: normal;
    }

    &__inner {
      position: relative;
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-width: @m_) {
      font-size: 23px;
      line-height: normal;
    }
  }

  .banner-wrap {
    position: relative;
    z-index: 1;
    background-color: @howBg;
    padding-bottom: 10px;

    &__bg-cont {
      max-width: 1600px;
      margin: auto;    
      height: 100%;
      position: relative;
    }

    &__bg {
      position: absolute;
      width: 100%;
      overflow: hidden;
      z-index: -1;
      background-image: url("../img/banner.jpg");
      background-position: right center;
      background-size: cover;
      height: 550px;
      background-repeat: no-repeat;

      &::after {
        .pseudo;
        .teeth(@howBg);
        top: auto;
        bottom: 0;
      }

      @media (max-width: @l_) and (min-width: @m) {
        background-position: center top;
      }

      @media (max-width: @m_) {
        background-position: left top;
      }
    }

    &__man {
      position: absolute;
      background-image: url(../img/man.png);
      background-repeat: no-repeat;
      background-position: right bottom;
      bottom: -15px;
      right: 20px;
      width: 511px;
      height: 447px;

      @media (max-width: @xl_) {
        right: -160px;
      }

      @media (max-width: 1050px) {
        right: -220px;
      }
    }

    &__icons {
      position: absolute;
      background-image: url(../img/icons-bg.png);
      top: -60px;
      right: 20px;
      width: 474px;
      height: 473px;

      @media (max-width: @xl_) {
        right: -80px;
      }

      @media (max-width: 1050px) {
        right: -120px;
      }
    }
  }

  .calc {
    position: relative;
    padding: 40px 20px;
    background-color: white;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.23);

    &__label {
      position: absolute;
      font-size: 14px;
      left: -15px;
      padding: 5px 10px 5px 15px;
      background-color: @iconBlue;
      color: white;
      text-transform: uppercase;

      &::before {
        .pseudo;
        bottom: -6px;
        left: 0;
        border-top: 3px solid darken(@iconBlue, 10%);
        border-right: 7px solid darken(@iconBlue, 10%);
        border-bottom: 3px solid transparent;
        border-left: 8px solid transparent;
      }

      &::after {
        .pseudo;
        top: 0;
        right: -8px;
        border-top: 15px solid @iconBlue;
        border-right: 8px solid transparent;
        border-bottom: 15px solid @iconBlue;
        border-left: 0px solid transparent;
      }
    }

    &__form-item {
      padding: 15px;
      box-sizing: border-box;

      &_wrap {
        @media (max-width: @s_) {
          flex-wrap: wrap;
        }
      }
    }

    &__input,
    input[type=text],
    select {
      width: 100%;
      height: 45px;
      font-size: 16px;
      padding: 0 @inputPadding;
      box-sizing: border-box;

      &::placeholder {
        .placeholder;
      }
    }

    &__input {

      &_placeholder {
        .placeholder!important;

        option {
          font-style: normal;
          color: @text;
        }
      }

      &_mini {
        width: auto!important;
      }

      &_file {
        .placeholder;
        background-color: @fileInputBg;
        padding-right: 0;
      }

      &_mail {
        width: auto!important;
        min-width: 100%;
        transition: 0s!important;

        @media (min-width: @m) {
          min-width: @mailMinW;
        }
      }
    }

    &__input-label {
      margin-right: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__input-divider {
      margin: 0 10px;
    }

    &__input-icon {
      right: 25px;
      color: @iconColor!important;
    }

    &__input-icon-file {
      width: 45px;
      color: @fileInputIcon;
      background-color: darken(@fileInputBg, 5%);
    }

    &__textarea-block {
      overflow: hidden;

      &_hidden {
        padding: 0;
        height: 0;
      }

      &_visible {
        height: 150px;
        padding: @inputPadding;
      }
    }

    &__textarea {
      width: 100%;
      height: 100%!important;
      resize: none;
      padding: @inputPadding!important;

      &::placeholder {
        .placeholder!important;
      }
    }

    @media (min-width: @l) {
      max-width: @calcWidth;
    }

    @media (max-width: @s_) {
      padding: 40px 0;
    }
  }



  .uk-form-icon .calc__input_icon[type=text] {
    padding-left: @inputPadding!important;
  }

  .calc__input_icon[type=file] {
    height: 45px;
    font-size: 0px!important;
    margin: 15px;
    box-sizing: border-box;
  }

  .main-block {
    padding-top: 50px;
    padding-bottom: 40px;

    &__descr {
      color: @mainDescr;
      font-style: italic;
      font-size: 16px;
      text-align: center;
      line-height: normal;
    }

    &__tail {
      position: absolute;
      top: -34px;
      left: 50%;
      margin-left: -26px;
      z-index: 1;
      border-radius: 50%;

      &::after {
        .pseudo;
        bottom: -1%;
        width: 16%;
        height: 4%;
        left: 42%;
      }
    }
  }

// How it works


.how {
  background-color: @howBg;

  .title__inner {
    background-color: @howBg;
  }

  & + .main-block {
    .main-block__tail {
      background-color: @howBg;
    }
  }
}

.how-list {
  padding: 10px;
  counter-reset: how-list__item;
  margin-bottom: 40px;

  &__item-cont {
    position: relative;
    margin-top: 90px;

    &:nth-child(even) {
      @media (min-width: @l) {
        margin-top: 140px; 
      }
    }

    @media (max-width: @l_) {
      margin-top: 70px; 
    }
  }

  &__item {
    position: relative;
    border-top: 2px dotted;
    border-bottom: 2px dotted;    
    margin: 0 30px;

    &_green {
      border-color: @buttonPrimary;

      .how-list__icon-block {
        background-color: @buttonPrimary;

        &::before {
          color: @buttonPrimary;
        }
      }
    }

    &_blue {
      border-color: @iconBlue;

      .how-list__icon-block {
        background-color: @iconBlue;

        &::before {
          color: @iconBlue;
        }
      }

    }

    @media (max-width: @l_) {
      border-bottom: 0; 
    }
  }

  &__icon-block {
    position: relative;
    margin: auto;   
    margin-top: -46px;
    width: 75px;
    height: 75px;
    border: 7px solid white;
    font-size: 35px;
    color: white;
    border-radius: 50%;

    &::before {
      .pseudo;
      bottom: -18px;
      width: 100%;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      content: counter(how-list__item);
      counter-increment: how-list__item;
      text-shadow: -3px -3px 0 @howBg, 3px -3px 0 @howBg, -3px 3px 0 @howBg, 3px 3px 0 @howBg, -1px -4px 0 @howBg, 1px -4px 0 @howBg;
    }
  }

  &__icon {

    &.uk-icon-tripadvisor {
      margin-top: 5px;
    }

    &_extra {
      position: absolute;
      width: 100%;
      text-align: center;
      font-size: 22px;
      top: 15px;
      left: 0;
    }
  }

  &__descr {
    margin: 30px 15px;
    font-size: 15px;
    color: @howItemDescr;
    text-align: center;
    font-weight: 600;
  }

  .sp-icons__arrow {
    position: absolute;
    right: -60px;

    &-1 {
      bottom: -36px;
    }

    &-2 {
      top: -40px;
    }

    &-3 {
      bottom: -30px;
    }
  }

  &__button {
    bottom: 0px;

    & + .how-list__item {
      margin-bottom: 60px;
    }

    &_bottom {
      @media (min-width: @l) {
        bottom: -20px;
      }
    }

    &_top {
      @media (min-width: @l) {
        bottom: auto;
        top: -120px;
      }
    }
  }
}

// Advantages Megauniver

.advantages {
  position: relative;
  background-color: @advantagesBg;

  .title__inner {
    background-color: @advantagesBg;
  }

  &__tail {

    &::after{
      background-color: @advantagesBg;
    }
  }

  &::after {
    .pseudo;
    .teeth(@advantagesBg);
  }

  & + .main-block {
    .main-block__tail {
      background-color: @advantagesBg;
    }
  }
}

.advantages-list {
  margin-top: 40px;

  &__item {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__img {
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  }

  &__descr {
    font-size: 15px;
    color: @advantageDescr;
  }
}


// On Megauniver you can

.ucan {
  position: relative;
  padding-bottom: 0;
  background-color: @ucanBg;

  .title__inner {
    background-color: @ucanBg;
  }

  &__tail {
    &::after{
      background-color: @ucanBg;
    }
  }

  &::after {
    .pseudo;
    .teeth(@ucanBg);
  }

  & + .main-block {
    .main-block__tail {
      background-color: @ucanBg2;
    }
  }
}

.ucan-list {
  max-width: 922px;
}

.ucan-list-item {
  border: 1px solid transparent;
  padding: 24px 15px 24px 22px;
  margin: 0;

  &__icon {
    width: 72px;
    height: 72px;
    margin-right: 22px;
    background-color: @iconBlue;
    border-radius: 50%;
    transition: 0.3s;
  }

  &__title {
    display: block;
    margin-bottom: 8px;
    font-size: 15px;
    color: @ucanLinkTitle;
    text-decoration: underline;
  }

  &__descr {
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: @ucanLinkDescr;
    text-decoration: none;
  }

  &__cur {
    font-family: "PT Sans", sans-serif;
  }

  &:hover,
  &:focus {
    border-color: darken(@ucanBg, 10%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.29);
    text-decoration: none;

    .ucan-list-item__icon {
      background-color: desaturate(lighten(@iconBlue, 23%), 23%);
    }
  }

  &:last-child {
    @media (max-width: @m_) {
      margin-bottom: 40px;
    }
  }

  @media (min-width: @m) {
    margin: 28px 0;
  }
}

.ucan-choose {
  padding: 35px 0;
  background-color: @ucanBg2;

  &__inner {
    max-width: 445px;
  }

  &__divider {
    font-size: 15px;
    font-weight: 600;
    color: #525252;

    @media (max-width: @m_) {
      margin: 20px 0;
    }
  }

  &__button {
    @media (max-width: @m_) {
      justify-content: center;
    }

    &_last {
      @media (max-width: @m_) {
        margin-bottom: 20px;
      }
    }
  }
}


// Payments

.payments-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Footer Pre

.footer-pre {
  position: relative;
  padding-top: 60px;
  background-color: @footerPreBg;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAMAAADXEh96AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjI2NDlDMzZDREQwMTFFNUI5RDJBMjY4NUMwN0EzRUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjI2NDlDMzdDREQwMTFFNUI5RDJBMjY4NUMwN0EzRUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMjY0OUMzNENERDAxMUU1QjlEMkEyNjg1QzA3QTNFQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMjY0OUMzNUNERDAxMUU1QjlEMkEyNjg1QzA3QTNFQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PiDoc1IAAAAGUExURQMNGjQ+S2dudgIAAAAYSURBVHjaYmBkZAAiBjAJohkRCFUQIMAAAiIAGVhUFvAAAAAASUVORK5CYII=');

  &__tail {
    &::after{
      background-color: @footerPreBg;
    }
  }

  & + .main-block {
    .main-block__tail {
      &::after {
        border-left-color: @footerPreBg;
        border-right-color: @footerPreBg;
      }
    }
  }
}

.logo-footer {
  &__logo {
    display: block;
  }

  &__mail {
    display: inline-block;
    font-size: 13px;
    color: white;
    font-style: italic;
    text-decoration: underline;
    margin-left: 55px;
    margin-top: 5px;

    @media (max-width: @l_) {
      margin-left: 48px;
    }
  }

  @media (max-width: @l_) {
    text-align: center;
    margin-bottom: 50px;
  }
}

.application {
  max-width: 300px;
  margin: auto;

  &__button {
    margin: auto;
  }

  &__note {
    text-align: center;
    font-size: 15px;
    color: white;
    font-style: italic;
    margin-top: 10px;
  }
}

.support {
  &__title {
    font-size: 13px;
    color: white;
  }

  &__phone {
    font-size: 32px;
    color: @iconBlue;
    font-weight: 700;
    margin: 8px 0;
  }

  &__note {
    font-size: 13px;
    color: white;
  }

  @media (max-width: @l_) {
    justify-content: center;
    text-align: center;
    margin-top: 45px;
  }
}


// Footer

.footer {
  padding-top: 45px;
  padding-bottom: 10px;
  border-top: 2px dotted rgba(255, 255, 255, 0.5);
  border-bottom: 2px dotted rgba(255, 255, 255, 0.5);
  background-color: @footerPreBg;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAMAAADXEh96AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEM5OTUxN0NDREQxMTFFNUI4MTNEOTY1QkYwNjI1NjYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEM5OTUxN0RDREQxMTFFNUI4MTNEOTY1QkYwNjI1NjYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowQzk5NTE3QUNERDExMUU1QjgxM0Q5NjVCRjA2MjU2NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowQzk5NTE3QkNERDExMUU1QjgxM0Q5NjVCRjA2MjU2NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pjjlxz4AAAAGUExURQAAADc3N6z5T+EAAAAWSURBVHjaYmBgZEQgBgiDAZsgQIABAAIiABlvUBejAAAAAElFTkSuQmCC');
}

.sub-menu-title {
  position: relative;
  font-size: 15px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: @subMenuPadding;
  padding-bottom: 2px;

  &::before {
    .arrow_bottom(rgba(255, 255, 255, 0.2), 8px);
    left: @subMenuPadding;
  }
}

.sub-menu-list {
  margin: 0;
  padding-left: @subMenuPadding;
  list-style-type: none;

  &__item {
    margin-bottom: 18px;
    line-height: 16px;
  }

  &__link {
    color: white;
    font-size: 13px;
    text-decoration: none;
    opacity: @opacityFooterLink;

    &:hover,
    &:focus {
      color: white;
      opacity: 0.5;
    }
  }
}


// Footer post

.footer-post {
  font-size: 13px;
  padding: 10px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  background-color: @footerPreBg;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAMAAADXEh96AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTVBNUFCMjVDREQyMTFFNUFGNjc4NEY3RTVCRjZFNjQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTVBNUFCMjZDREQyMTFFNUFGNjc4NEY3RTVCRjZFNjQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NUE1QUIyM0NERDIxMUU1QUY2Nzg0RjdFNUJGNkU2NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NUE1QUIyNENERDIxMUU1QUY2Nzg0RjdFNUJGNkU2NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pufj9VIAAAAGUExURRQUFEdHR1VunnwAAAAYSURBVHjaYmBkZAAiBjAJohkRCFUQIMAAAiIAGVhUFvAAAAAASUVORK5CYII=');
}