/*
* Third Party
*/
@import "../../bower_components/normalize.css/normalize.css";
/*
* Custom
*/
@import url(http://fonts.googleapis.com/css?family=PT+Sans:400,600,700,800&text=%E2%82%B6);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800,700,600,400italic&subset=latin,cyrillic-ext);
.sp-icons {
  background-image: url(../img/icons.png);
}
.sp-icons__arrow-1 {
  background-position: -124px -220px;
  width: 89px;
  height: 23px;
}
.sp-icons__arrow-2 {
  background-position: -124px -186px;
  width: 94px;
  height: 34px;
}
.sp-icons__arrow-3 {
  background-position: -248px 0px;
  width: 93px;
  height: 32px;
}
.sp-icons__doc-1 {
  background-position: -300px -136px;
  width: 35px;
  height: 50px;
}
.sp-icons__doc-2 {
  background-position: -300px -84px;
  width: 35px;
  height: 50px;
}
.sp-icons__doc-3 {
  background-position: 0px -248px;
  width: 43px;
  height: 57px;
}
.sp-icons__doc-4 {
  background-position: -300px -188px;
  width: 35px;
  height: 50px;
}
.sp-icons__doc-5 {
  background-position: -43px -248px;
  width: 35px;
  height: 50px;
}
.sp-icons__doc-6 {
  background-position: -300px -32px;
  width: 35px;
  height: 50px;
}
.sp-icons__es {
  background-position: 0px -186px;
  width: 124px;
  height: 62px;
}
.sp-icons__qiwi {
  background-position: 0px 0px;
  width: 124px;
  height: 62px;
}
.sp-icons__sms {
  background-position: -124px -124px;
  width: 124px;
  height: 62px;
}
.sp-icons__svyaz {
  background-position: 0px -124px;
  width: 124px;
  height: 62px;
}
.sp-icons__t-blue {
  background-position: -248px -32px;
  width: 52px;
  height: 52px;
}
.sp-icons__t-dark {
  background-position: -248px -188px;
  width: 52px;
  height: 52px;
}
.sp-icons__t-white {
  background-position: -248px -136px;
  width: 52px;
  height: 52px;
}
.sp-icons__tail {
  background-position: -248px -84px;
  width: 52px;
  height: 52px;
}
.sp-icons__visa {
  background-position: -124px -62px;
  width: 124px;
  height: 62px;
}
.sp-icons__wm {
  background-position: -124px 0px;
  width: 124px;
  height: 62px;
}
.sp-icons__yad {
  background-position: 0px -62px;
  width: 124px;
  height: 62px;
}
body {
  font-family: "Open Sans", sans-serif;
  transition: 1s;
}
h1,
h2,
h3,
h4 {
  font-family: "Open Sans", sans-serif;
}
a {
  transition: 0.3s;
}
.pseudo {
  position: absolute;
  display: block;
  content: "";
}
.placeholder {
  font-size: 16px;
  font-style: italic;
  color: #c9c9c9;
}
@media (min-width: 960px) {
  .uk-container {
    max-width: 1030px;
  }
  .uk-container_calc {
    max-width: 1110px;
  }
}
@media (min-width: 1220px) {
  .uk-container {
    max-width: 1270px;
  }
  .uk-container_calc {
    max-width: 1350px;
  }
}
.sp-icons {
  display: block;
}
.logo-cont {
  line-height: normal;
}
.logo:hover,
.logo:focus {
  text-decoration: none;
}
.logo__text {
  margin: 5px 0 0 10px;
}
.logo__title {
  font-size: 27px;
  color: #2783c2;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 22px;
}
.logo__beta {
  text-transform: none;
  font-style: italic;
  font-size: 17px;
  font-weight: 700;
  position: relative;
  top: -14px;
  left: 4px;
}
.logo__tagline {
  font-size: 14px;
  color: #818181;
  font-style: italic;
  text-transform: lowercase;
}
@media (max-width: 959px) {
  .logo {
    margin: auto;
  }
}
@media (max-width: 767px) {
  .head-button.head-item {
    flex-direction: column;
    align-items: stretch;
    margin-top: 0;
  }
}
.link:hover {
  cursor: pointer;
}
.link_before-button {
  margin-top: 5px;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .link_before-button {
    margin-right: 60px;
  }
}
.link_before-button-mini {
  margin-right: 25px;
}
.link__icon {
  font-size: 15px;
  margin-right: 2px;
}
.link__icon_blue {
  color: #2783c2;
}
.link__icon_green {
  color: #00cd98;
}
.link__text {
  transition: 0.3s;
}
.link__text_icon {
  font-size: 15px;
  color: #727272;
}
.link__text_dotted {
  border-bottom: 1px dotted;
}
.link__text_blue {
  color: #0086c0;
}
.link__text_semibold {
  font-weight: 600;
}
.link__text_small {
  font-size: 14px;
}
.link:hover,
.link:focus {
  text-decoration: none;
}
.link:hover .link__text_dotted,
.link:focus .link__text_dotted {
  border-color: transparent;
}
.uk-form .button,
.button {
  color: white;
  font-weight: 600;
  transition: 0.3s;
}
.uk-form .button:hover,
.button:hover,
.uk-form .button:focus,
.button:focus {
  color: white;
}
.uk-form .button_m,
.button_m {
  font-size: 15px;
  padding: 7px 20px;
}
.uk-form .button_l,
.button_l {
  font-size: 16px;
  padding: 10px 17px;
}
@media (max-width: 479px) {
  .uk-form .button_l,
  .button_l {
    padding: 5px 10px;
  }
}
.uk-form .button_xl,
.button_xl {
  font-size: 19px;
  font-weight: 700;
  padding: 17px 17px 17px 25px;
}
@media (max-width: 479px) {
  .uk-form .button_xl,
  .button_xl {
    padding: 10px 10px 10px 18px;
  }
}
.uk-form .button_dotted,
.button_dotted {
  font-size: 14px;
  font-weight: 300;
  color: #727272;
  background-color: white;
  border: 2px dotted #00cd98;
}
.uk-form .button_dotted:hover,
.button_dotted:hover,
.uk-form .button_dotted:focus,
.button_dotted:focus {
  background-color: #00cd98;
  color: white;
}
.uk-form .button_green:active,
.button_green:active,
.uk-form .button_green,
.button_green {
  background-color: #00cd98;
  box-shadow: 1px 1px 0 #009a72;
}
.uk-form .button_green:active:hover,
.button_green:active:hover,
.uk-form .button_green:hover,
.button_green:hover,
.uk-form .button_green:active:focus,
.button_green:active:focus,
.uk-form .button_green:focus,
.button_green:focus {
  background-color: #00be8d;
}
.uk-form .button_blue:active,
.button_blue:active,
.uk-form .button_blue,
.button_blue {
  background-color: #2783c2;
  box-shadow: 1px 1px 0 #1e6698;
}
.uk-form .button_blue:active:hover,
.button_blue:active:hover,
.uk-form .button_blue:hover,
.button_blue:hover,
.uk-form .button_blue:active:focus,
.button_blue:active:focus,
.uk-form .button_blue:focus,
.button_blue:focus {
  background-color: #247ab5;
}
.uk-form .button_arrow,
.button_arrow {
  position: relative;
}
.uk-form .button_arrow::before,
.button_arrow::before,
.uk-form .button_arrow::after,
.button_arrow::after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  border-left: 10px solid;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transition: 0.3s;
}
.uk-form .button_arrow::before,
.button_arrow::before {
  z-index: 1;
}
.uk-form .button_arrow::after,
.button_arrow::after {
  top: 1px;
}
.uk-form .button_arrow-green:hover::before,
.button_arrow-green:hover::before,
.uk-form .button_arrow-green:focus::before,
.button_arrow-green:focus::before {
  border-left-color: #00be8d;
}
.uk-form .button_arrow-green::before,
.button_arrow-green::before {
  border-left-color: #00cd98;
}
.uk-form .button_arrow-green::after,
.button_arrow-green::after {
  border-left-color: #009a72;
}
.uk-form .button_arrow-blue:hover::before,
.button_arrow-blue:hover::before,
.uk-form .button_arrow-blue:focus::before,
.button_arrow-blue:focus::before {
  border-left-color: #247ab5;
}
.uk-form .button_arrow-blue::before,
.button_arrow-blue::before {
  border-left-color: #2783c2;
}
.uk-form .button_arrow-blue::after,
.button_arrow-blue::after {
  border-left-color: #1e6698;
}
.uk-form .button_arrow-m,
.button_arrow-m {
  margin-right: 15px;
}
.uk-form .button_arrow-m::before,
.button_arrow-m::before {
  right: -15px;
  border-top-width: 22px;
  border-bottom-width: 22px;
  border-left-width: 15px;
}
.uk-form .button_arrow-m::after,
.button_arrow-m::after {
  right: -16px;
  border-top-width: 21px;
  border-bottom-width: 23px;
  border-left-width: 15px;
}
.uk-form .button_arrow-xl,
.button_arrow-xl {
  margin-right: 20px;
}
.uk-form .button_arrow-xl::before,
.button_arrow-xl::before {
  right: -20px;
  border-top-width: 33px;
  border-bottom-width: 32px;
  border-left-width: 20px;
}
@media (max-width: 479px) {
  .uk-form .button_arrow-xl::before,
  .button_arrow-xl::before {
    right: -13px;
    border-top-width: 26px;
    border-bottom-width: 25px;
    border-left-width: 13px;
  }
}
.uk-form .button_arrow-xl::after,
.button_arrow-xl::after {
  right: -21px;
  border-top-width: 33px;
  border-bottom-width: 31px;
  border-left-width: 20px;
}
@media (max-width: 479px) {
  .uk-form .button_arrow-xl::after,
  .button_arrow-xl::after {
    right: -14px;
    border-top-width: 26px;
    border-bottom-width: 24px;
    border-left-width: 13px;
  }
}
.uk-form .button_calc-cost,
.button_calc-cost {
  margin-top: -9px;
  margin-left: 30px;
}
@media (max-width: 767px) {
  .uk-form .button_calc-cost,
  .button_calc-cost {
    margin-top: 20px;
    margin-left: 0;
  }
}
.head-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .head-item {
    justify-content: space-between;
  }
}
.title {
  font-size: 35px;
  font-weight: 600;
  color: #2d2d2d;
  text-align: center;
  line-height: 40px;
}
.title_banner {
  color: white;
  font-size: 31px;
  text-align: left;
  margin-top: 35px;
  margin-bottom: 50px;
  max-width: 810px;
}
@media (max-width: 767px) {
  .title_banner {
    font-size: 21px;
    line-height: normal;
  }
}
.title_calc {
  font-size: 26px;
  font-weight: 700;
  color: #212123;
  text-align: left;
  margin-left: 115px;
  margin-top: -6px;
  margin-right: 15px;
}
@media (max-width: 767px) {
  .title_calc {
    margin-left: 15px;
    margin-top: 50px;
  }
}
.title_line-through {
  position: relative;
}
.title_line-through::before {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  right: 0;
  top: 50%;
  z-index: 0;
  border-top: 3px solid #e4e4e4;
}
.title_advantage {
  font-size: 18px;
  color: #272727;
  line-height: normal;
}
.title__inner {
  position: relative;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 767px) {
  .title {
    font-size: 23px;
    line-height: normal;
  }
}
.banner-wrap {
  position: relative;
  z-index: 1;
  background-color: #eeeeee;
  padding-bottom: 10px;
}
.banner-wrap__bg-cont {
  max-width: 1600px;
  margin: auto;
  height: 100%;
  position: relative;
}
.banner-wrap__bg {
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  background-image: url("../img/banner.jpg");
  background-position: right center;
  background-size: cover;
  height: 550px;
  background-repeat: no-repeat;
}
.banner-wrap__bg::after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  top: -8px;
  border-bottom: 8px solid transparent;
  background-image: linear-gradient(#eeeeee, #eeeeee), linear-gradient(to bottom right, transparent 50.5%, #eeeeee 50.5%), linear-gradient(to bottom left, transparent 50.5%, #eeeeee 50.5%);
  background-repeat: repeat, repeat-x, repeat-x;
  background-position: 0 0, 8px 0, 8px 0;
  background-size: auto auto, 16px 16px, 16px 16px, 16px 16px, 16px 16px;
  background-clip: padding-box, border-box, border-box, border-box, border-box;
  background-origin: padding-box, border-box, border-box, border-box, border-box;
  background-color: #eeeeee;
  background-color: rgba(0, 0, 0, 0);
  top: auto;
  bottom: 0;
}
@media (max-width: 959px) and (min-width: 768px) {
  .banner-wrap__bg {
    background-position: center top;
  }
}
@media (max-width: 767px) {
  .banner-wrap__bg {
    background-position: left top;
  }
}
.banner-wrap__man {
  position: absolute;
  background-image: url(../img/man.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  bottom: -15px;
  right: 20px;
  width: 511px;
  height: 447px;
}
@media (max-width: 1219px) {
  .banner-wrap__man {
    right: -160px;
  }
}
@media (max-width: 1050px) {
  .banner-wrap__man {
    right: -220px;
  }
}
.banner-wrap__icons {
  position: absolute;
  background-image: url(../img/icons-bg.png);
  top: -60px;
  right: 20px;
  width: 474px;
  height: 473px;
}
@media (max-width: 1219px) {
  .banner-wrap__icons {
    right: -80px;
  }
}
@media (max-width: 1050px) {
  .banner-wrap__icons {
    right: -120px;
  }
}
.calc {
  position: relative;
  padding: 40px 20px;
  background-color: white;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.23);
}
.calc__label {
  position: absolute;
  font-size: 14px;
  left: -15px;
  padding: 5px 10px 5px 15px;
  background-color: #2783c2;
  color: white;
  text-transform: uppercase;
}
.calc__label::before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 0;
  border-top: 3px solid #1e6698;
  border-right: 7px solid #1e6698;
  border-bottom: 3px solid transparent;
  border-left: 8px solid transparent;
}
.calc__label::after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: -8px;
  border-top: 15px solid #2783c2;
  border-right: 8px solid transparent;
  border-bottom: 15px solid #2783c2;
  border-left: 0px solid transparent;
}
.calc__form-item {
  padding: 15px;
  box-sizing: border-box;
}
@media (max-width: 479px) {
  .calc__form-item_wrap {
    flex-wrap: wrap;
  }
}
.calc__input,
.calc input[type=text],
.calc select {
  width: 100%;
  height: 45px;
  font-size: 16px;
  padding: 0 15px;
  box-sizing: border-box;
}
.calc__input::placeholder,
.calc input[type=text]::placeholder,
.calc select::placeholder {
  font-size: 16px;
  font-style: italic;
  color: #c9c9c9;
}
.calc__input_placeholder {
  font-size: 16px !important;
  font-style: italic !important;
  color: #c9c9c9 !important;
}
.calc__input_placeholder option {
  font-style: normal;
  color: #303030;
}
.calc__input_mini {
  width: auto!important;
}
.calc__input_file {
  font-size: 16px;
  font-style: italic;
  color: #c9c9c9;
  background-color: #ebf2fc;
  padding-right: 0;
}
.calc__input_mail {
  width: auto!important;
  min-width: 100%;
  transition: 0s!important;
}
@media (min-width: 768px) {
  .calc__input_mail {
    min-width: 350px;
  }
}
.calc__input-label {
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.calc__input-divider {
  margin: 0 10px;
}
.calc__input-icon {
  right: 25px;
  color: #cbcbcb !important;
}
.calc__input-icon-file {
  width: 45px;
  color: #aab9cd;
  background-color: #d5e4f9;
}
.calc__textarea-block {
  overflow: hidden;
}
.calc__textarea-block_hidden {
  padding: 0;
  height: 0;
}
.calc__textarea-block_visible {
  height: 150px;
  padding: 15px;
}
.calc__textarea {
  width: 100%;
  height: 100%!important;
  resize: none;
  padding: 15px !important;
}
.calc__textarea::placeholder {
  font-size: 16px !important;
  font-style: italic !important;
  color: #c9c9c9 !important;
}
@media (min-width: 960px) {
  .calc {
    max-width: 810px;
  }
}
@media (max-width: 479px) {
  .calc {
    padding: 40px 0;
  }
}
.uk-form-icon .calc__input_icon[type=text] {
  padding-left: 15px !important;
}
.calc__input_icon[type=file] {
  height: 45px;
  font-size: 0px!important;
  margin: 15px;
  box-sizing: border-box;
}
.main-block {
  padding-top: 50px;
  padding-bottom: 40px;
}
.main-block__descr {
  color: #7b7b7b;
  font-style: italic;
  font-size: 16px;
  text-align: center;
  line-height: normal;
}
.main-block__tail {
  position: absolute;
  top: -34px;
  left: 50%;
  margin-left: -26px;
  z-index: 1;
  border-radius: 50%;
}
.main-block__tail::after {
  position: absolute;
  display: block;
  content: "";
  bottom: -1%;
  width: 16%;
  height: 4%;
  left: 42%;
}
.how {
  background-color: #eeeeee;
}
.how .title__inner {
  background-color: #eeeeee;
}
.how + .main-block .main-block__tail {
  background-color: #eeeeee;
}
.how-list {
  padding: 10px;
  counter-reset: how-list__item;
  margin-bottom: 40px;
}
.how-list__item-cont {
  position: relative;
  margin-top: 90px;
}
@media (min-width: 960px) {
  .how-list__item-cont:nth-child(even) {
    margin-top: 140px;
  }
}
@media (max-width: 959px) {
  .how-list__item-cont {
    margin-top: 70px;
  }
}
.how-list__item {
  position: relative;
  border-top: 2px dotted;
  border-bottom: 2px dotted;
  margin: 0 30px;
}
.how-list__item_green {
  border-color: #00cd98;
}
.how-list__item_green .how-list__icon-block {
  background-color: #00cd98;
}
.how-list__item_green .how-list__icon-block::before {
  color: #00cd98;
}
.how-list__item_blue {
  border-color: #2783c2;
}
.how-list__item_blue .how-list__icon-block {
  background-color: #2783c2;
}
.how-list__item_blue .how-list__icon-block::before {
  color: #2783c2;
}
@media (max-width: 959px) {
  .how-list__item {
    border-bottom: 0;
  }
}
.how-list__icon-block {
  position: relative;
  margin: auto;
  margin-top: -46px;
  width: 75px;
  height: 75px;
  border: 7px solid white;
  font-size: 35px;
  color: white;
  border-radius: 50%;
}
.how-list__icon-block::before {
  position: absolute;
  display: block;
  content: "";
  bottom: -18px;
  width: 100%;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  content: counter(how-list__item);
  counter-increment: how-list__item;
  text-shadow: -3px -3px 0 #eeeeee, 3px -3px 0 #eeeeee, -3px 3px 0 #eeeeee, 3px 3px 0 #eeeeee, -1px -4px 0 #eeeeee, 1px -4px 0 #eeeeee;
}
.how-list__icon.uk-icon-tripadvisor {
  margin-top: 5px;
}
.how-list__icon_extra {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 22px;
  top: 15px;
  left: 0;
}
.how-list__descr {
  margin: 30px 15px;
  font-size: 15px;
  color: #5e5e5e;
  text-align: center;
  font-weight: 600;
}
.how-list .sp-icons__arrow {
  position: absolute;
  right: -60px;
}
.how-list .sp-icons__arrow-1 {
  bottom: -36px;
}
.how-list .sp-icons__arrow-2 {
  top: -40px;
}
.how-list .sp-icons__arrow-3 {
  bottom: -30px;
}
.how-list__button {
  bottom: 0px;
}
.how-list__button + .how-list__item {
  margin-bottom: 60px;
}
@media (min-width: 960px) {
  .how-list__button_bottom {
    bottom: -20px;
  }
}
@media (min-width: 960px) {
  .how-list__button_top {
    bottom: auto;
    top: -120px;
  }
}
.advantages {
  position: relative;
  background-color: white;
}
.advantages .title__inner {
  background-color: white;
}
.advantages__tail::after {
  background-color: white;
}
.advantages::after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  top: -8px;
  border-bottom: 8px solid transparent;
  background-image: linear-gradient(white, white), linear-gradient(to bottom right, transparent 50.5%, white 50.5%), linear-gradient(to bottom left, transparent 50.5%, white 50.5%);
  background-repeat: repeat, repeat-x, repeat-x;
  background-position: 0 0, 8px 0, 8px 0;
  background-size: auto auto, 16px 16px, 16px 16px, 16px 16px, 16px 16px;
  background-clip: padding-box, border-box, border-box, border-box, border-box;
  background-origin: padding-box, border-box, border-box, border-box, border-box;
  background-color: white;
  background-color: rgba(0, 0, 0, 0);
}
.advantages + .main-block .main-block__tail {
  background-color: white;
}
.advantages-list {
  margin-top: 40px;
}
.advantages-list__item {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.advantages-list__img {
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}
.advantages-list__descr {
  font-size: 15px;
  color: #636363;
}
.ucan {
  position: relative;
  padding-bottom: 0;
  background-color: #c4d6e4;
}
.ucan .title__inner {
  background-color: #c4d6e4;
}
.ucan__tail::after {
  background-color: #c4d6e4;
}
.ucan::after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  top: -8px;
  border-bottom: 8px solid transparent;
  background-image: linear-gradient(#c4d6e4, #c4d6e4), linear-gradient(to bottom right, transparent 50.5%, #c4d6e4 50.5%), linear-gradient(to bottom left, transparent 50.5%, #c4d6e4 50.5%);
  background-repeat: repeat, repeat-x, repeat-x;
  background-position: 0 0, 8px 0, 8px 0;
  background-size: auto auto, 16px 16px, 16px 16px, 16px 16px, 16px 16px;
  background-clip: padding-box, border-box, border-box, border-box, border-box;
  background-origin: padding-box, border-box, border-box, border-box, border-box;
  background-color: #c4d6e4;
  background-color: rgba(0, 0, 0, 0);
}
.ucan + .main-block .main-block__tail {
  background-color: #a6c6de;
}
.ucan-list {
  max-width: 922px;
}
.ucan-list-item {
  border: 1px solid transparent;
  padding: 24px 15px 24px 22px;
  margin: 0;
}
.ucan-list-item__icon {
  width: 72px;
  height: 72px;
  margin-right: 22px;
  background-color: #2783c2;
  border-radius: 50%;
  transition: 0.3s;
}
.ucan-list-item__title {
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
  color: #2772a5;
  text-decoration: underline;
}
.ucan-list-item__descr {
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: #202020;
  text-decoration: none;
}
.ucan-list-item__cur {
  font-family: "PT Sans", sans-serif;
}
.ucan-list-item:hover,
.ucan-list-item:focus {
  border-color: #a1bed4;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.29);
  text-decoration: none;
}
.ucan-list-item:hover .ucan-list-item__icon,
.ucan-list-item:focus .ucan-list-item__icon {
  background-color: #8cb6d2;
}
@media (max-width: 767px) {
  .ucan-list-item:last-child {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .ucan-list-item {
    margin: 28px 0;
  }
}
.ucan-choose {
  padding: 35px 0;
  background-color: #a6c6de;
}
.ucan-choose__inner {
  max-width: 445px;
}
.ucan-choose__divider {
  font-size: 15px;
  font-weight: 600;
  color: #525252;
}
@media (max-width: 767px) {
  .ucan-choose__divider {
    margin: 20px 0;
  }
}
@media (max-width: 767px) {
  .ucan-choose__button {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .ucan-choose__button_last {
    margin-bottom: 20px;
  }
}
.payments-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-pre {
  position: relative;
  padding-top: 60px;
  background-color: #343e4b;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAMAAADXEh96AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjI2NDlDMzZDREQwMTFFNUI5RDJBMjY4NUMwN0EzRUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjI2NDlDMzdDREQwMTFFNUI5RDJBMjY4NUMwN0EzRUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMjY0OUMzNENERDAxMUU1QjlEMkEyNjg1QzA3QTNFQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMjY0OUMzNUNERDAxMUU1QjlEMkEyNjg1QzA3QTNFQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PiDoc1IAAAAGUExURQMNGjQ+S2dudgIAAAAYSURBVHjaYmBkZAAiBjAJohkRCFUQIMAAAiIAGVhUFvAAAAAASUVORK5CYII=');
}
.footer-pre__tail::after {
  background-color: #343e4b;
}
.footer-pre + .main-block .main-block__tail::after {
  border-left-color: #343e4b;
  border-right-color: #343e4b;
}
.logo-footer__logo {
  display: block;
}
.logo-footer__mail {
  display: inline-block;
  font-size: 13px;
  color: white;
  font-style: italic;
  text-decoration: underline;
  margin-left: 55px;
  margin-top: 5px;
}
@media (max-width: 959px) {
  .logo-footer__mail {
    margin-left: 48px;
  }
}
@media (max-width: 959px) {
  .logo-footer {
    text-align: center;
    margin-bottom: 50px;
  }
}
.application {
  max-width: 300px;
  margin: auto;
}
.application__button {
  margin: auto;
}
.application__note {
  text-align: center;
  font-size: 15px;
  color: white;
  font-style: italic;
  margin-top: 10px;
}
.support__title {
  font-size: 13px;
  color: white;
}
.support__phone {
  font-size: 32px;
  color: #2783c2;
  font-weight: 700;
  margin: 8px 0;
}
.support__note {
  font-size: 13px;
  color: white;
}
@media (max-width: 959px) {
  .support {
    justify-content: center;
    text-align: center;
    margin-top: 45px;
  }
}
.footer {
  padding-top: 45px;
  padding-bottom: 10px;
  border-top: 2px dotted rgba(255, 255, 255, 0.5);
  border-bottom: 2px dotted rgba(255, 255, 255, 0.5);
  background-color: #343e4b;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAMAAADXEh96AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEM5OTUxN0NDREQxMTFFNUI4MTNEOTY1QkYwNjI1NjYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEM5OTUxN0RDREQxMTFFNUI4MTNEOTY1QkYwNjI1NjYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowQzk5NTE3QUNERDExMUU1QjgxM0Q5NjVCRjA2MjU2NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowQzk5NTE3QkNERDExMUU1QjgxM0Q5NjVCRjA2MjU2NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pjjlxz4AAAAGUExURQAAADc3N6z5T+EAAAAWSURBVHjaYmBgZEQgBgiDAZsgQIABAAIiABlvUBejAAAAAElFTkSuQmCC');
}
.sub-menu-title {
  position: relative;
  font-size: 15px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 20px;
  padding-bottom: 2px;
}
.sub-menu-title::before {
  position: absolute;
  display: block;
  content: "";
  border-top: 8px solid rgba(255, 255, 255, 0.2);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -9px;
  left: 20px;
}
.sub-menu-list {
  margin: 0;
  padding-left: 20px;
  list-style-type: none;
}
.sub-menu-list__item {
  margin-bottom: 18px;
  line-height: 16px;
}
.sub-menu-list__link {
  color: white;
  font-size: 13px;
  text-decoration: none;
  opacity: 0.8;
}
.sub-menu-list__link:hover,
.sub-menu-list__link:focus {
  color: white;
  opacity: 0.5;
}
.footer-post {
  font-size: 13px;
  padding: 10px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  background-color: #343e4b;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAMAAADXEh96AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTVBNUFCMjVDREQyMTFFNUFGNjc4NEY3RTVCRjZFNjQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTVBNUFCMjZDREQyMTFFNUFGNjc4NEY3RTVCRjZFNjQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NUE1QUIyM0NERDIxMUU1QUY2Nzg0RjdFNUJGNkU2NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NUE1QUIyNENERDIxMUU1QUY2Nzg0RjdFNUJGNkU2NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pufj9VIAAAAGUExURRQUFEdHR1VunnwAAAAYSURBVHjaYmBkZAAiBjAJohkRCFUQIMAAAiIAGVhUFvAAAAAASUVORK5CYII=');
}
